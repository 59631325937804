@import "~schaffrath/variables";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~base/experience/components/commerceLayouts/layout";

html,
body {
	line-height: $line-height-base;
}

// overwrite nested grid for component grid1r1cMainCentral
.grid1r1cMainCentral .experience-commerce_layouts-mobileGrid2r1c {
	> .mobile-2r-1c > .row {

		@include media-breakpoint-up('md') {
			.col-sm-6 {
				&:first-of-type {
					flex-basis: 66.6%;
					max-width: 66.6%;
					max-height: 48.875rem; // 782px
					overflow: hidden;
				}

				&:nth-of-type(2) {
					flex-basis: 33.3%;
					max-width: 33.3%;
					max-height: 48.875rem; // 782px
					overflow: hidden;
				}

				&:first-of-type .col-sm-6 {
					flex-basis: 50%;
					max-width: 50%;
				}

				&:first-of-type .col-12 {
					max-height: 28.25rem; // 452px
				}

				&:first-of-type .col-12.col-sm-6 {
					max-height: 20.375rem; // 326px
				}

				&:nth-of-type(2) .col-sm-6 {
					flex-basis: 50%;
					max-width: 50%;
					max-height: 48.875rem; // 782px
					overflow: hidden;
				}
			}
		}
	}

	.mobile-2r-1c .mobile-1r-1c .region {
		margin-bottom: 0;
	}
}

#homepage .container {
	.ml-n2,
	.mr-n2,
	.mx-n2 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}

	> .row > .col-12 > .experience-component:not(.experience-commerce_assets-spacer) {

		@include media-breakpoint-only('md') {
			margin-bottom: 1.5625rem; // 25px
		}

		@include media-breakpoint-up('lg') {
			margin-bottom: 3.125rem; // 50px
		}
	}

	@include media-breakpoint-down('sm') {
		[class*="experience-commerce_assets-"],
		.experience-commerce_layouts-carousel {
			margin-bottom: 0.125rem; // 2px
		}
	}
}

#maincontent .grid1r1cMainCentral {

	@include media-breakpoint-up(md) {
		max-width: #{map-get($container-max-widths, lg)};
		margin: 0 auto;

		//reset gutters for all nested rows and cols
		.row {
			margin-right: -2px;
			margin-left: -2px;

			.col,
			[class*="col-"] {
				padding-right: 2px;
				padding-left: 2px;
			}
		}

		.ml-n2,
		.mr-n2,
		.mx-n2 {
			margin-right: -2px !important;
			margin-left: -2px !important;
		}

		.region {
			margin-bottom: 0.25rem;
		}
	}
}
