@import "~base/variables";

$grid-breakpoints: (
	xs: 0,
	sm: 544px,
	md: 768px,
	lg: 992px,
	xl: 1200px
);

$container-max-widths: (
	lg: 68.75rem, /* 1100px */
	xl: 75.625rem /* 1210px */
);

// Bootstrap overrides

$border-color: $grey4;
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

// colors
$grey25: #ebebeb;
$grey52: #616060;
$grey55: #54575a;
$grey65: #424242;
$primary: $grey65;
$secondary: #e30714;

// buttons new colors
$btn-blue: #3769e2;
$btn-dark-blue: #1a44a9;
$btn-gray: #e0e0e0;
$btn-gray-light: #e0e0e0;
$btn-gray-dark: #333333;
$btn-pink: #bb0a51;
$btn-cherry: #88053a;

$gray-900: $grey65;
$body-bg: $white;

$border-width: 1px;
$border-radius: 0;
$border-radius-lg: $border-radius;
$border-radius-sm: $border-radius;

// Fonts
$font-family-sans-serif: "Roboto", "Helvetica Neue", "Arial", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$line-height-base: 1.5;
$headings-line-height: $line-height-base;

//Navbar
$navbar-light-color: $grey4;

// Spacer
$spacer: 1rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
	(
		0: 0,
		1: ($spacer * 0.25),
		2: ($spacer * 0.5),
		3: $spacer,
		4: ($spacer * 1.5),
		5: ($spacer * 3),
		300: ($spacer * 1.875)
	),
	$spacers
);

$link-hover-decoration: none;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.5625rem; //9px
$input-btn-font-size: 0.75rem; // 12px
$input-btn-line-height: 1.3;

$input-btn-focus-width: 0.2rem !default;
$input-btn-focus-color: #d0d0d0;
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm: $input-btn-padding-y;
$input-btn-font-size-sm: $input-btn-font-size;

$input-btn-padding-y-lg: $input-btn-padding-y;
$input-btn-font-size-lg: $input-btn-font-size;

$input-btn-border-width: $border-width;

$input-btn-hover-bgColor: $grey52;

// Buttons
$btn-font-weight: 500;
$btn-font-size: 0.75rem;
$btn-border-radius: 0;
$btn-focus-box-shadow: none;

// Forms
$label-margin-bottom: 0.1rem;

$input-color: $grey65;
$input-font-weight: 500;
$input-padding-y: $input-btn-padding-y;
$input-line-height: $input-btn-line-height;
$input-line-height-sm: $input-line-height;
$input-line-height-lg: $input-line-height;
$input-border-width: $input-btn-border-width;
$input-border-color: $grey4;
$input-focus-border-color: $grey4;
$input-placeholder-color: $grey3;

$input-height-border: $input-border-width * 2;

$input-height: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border});
$input-height-sm: calc(#{$input-line-height-sm * 1em} + #{$input-btn-padding-y-sm * 2} + #{$input-height-border});
$input-height-lg: calc(#{$input-line-height-lg * 1em} + #{$input-btn-padding-y-lg * 2} + #{$input-height-border});

$hr-border-color-dark: $grey4;
$component-active-bg: $grey65;

$custom-control-indicator-size:         0.875rem;
$custom-control-indicator-bg:           $grey65;

$custom-control-indicator-checked-color:        $white;
$custom-control-indicator-checked-border-color:  $grey4;

$custom-control-indicator-focus-border-color:   $grey4;

$custom-control-indicator-active-bg:            $white;
$custom-control-indicator-active-border-color:   $grey4;

$svg-check: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23");

$custom-checkbox-indicator-border-radius: 2px;

$custom-radio-indicator-icon-checked:           str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -3 7 7'%3e%3ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3e%3c/svg%3e"), "#", "%23");

$custom-select-font-weight: 500;
$custom-select-bg-size: 22px;
$custom-select-indicator-color: $grey65;
$custom-select-indicator: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpath fill='#{$custom-select-indicator-color}' d='M143.993 288c4.454 0 8.885 1.849 12.048 5.464L256 407.703l99.959-114.239c5.818-6.65 15.928-7.325 22.577-1.505s7.324 15.927 1.505 22.577l-112 128a16 16 0 01-24.082 0l-112-128c-5.819-6.65-5.146-16.758 1.505-22.577A15.938 15.938 0 01143.993 288z'/%3e%3c/svg%3e"), "#", "%23");
$custom-select-position: 97% 23%;
$custom-select-background: $custom-select-indicator no-repeat $custom-select-position / $custom-select-bg-size;

// Breadcrumbs
$breadcrumb-item-padding: 0.3125rem;

// Dropdowns
$dropdown-link-active-bg: $grey1;
